import { render, staticRenderFns } from "./ProductCardDefault.vue?vue&type=template&id=7c8f5a04&scoped=true&"
import script from "./ProductCardDefault.vue?vue&type=script&lang=js&"
export * from "./ProductCardDefault.vue?vue&type=script&lang=js&"
import style1 from "./ProductCardDefault.vue?vue&type=style&index=1&id=7c8f5a04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c8f5a04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WishListBtn: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/WishListBtn.vue').default,ProductCardTop: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/ProductCard/ProductCardTop.vue').default,ProductCardSizes: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/ProductCard/ProductCardSizes.vue').default,ProductCardRelationShip: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/ProductCard/ProductCardRelationShip.vue').default})
