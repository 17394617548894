import { render, staticRenderFns } from "./AddressNew.vue?vue&type=template&id=e32c5d1e&"
import script from "./AddressNew.vue?vue&type=script&lang=js&"
export * from "./AddressNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports