import { render, staticRenderFns } from "./ConfirmedView.vue?vue&type=template&id=95da98a8&scoped=true&"
import script from "./ConfirmedView.vue?vue&type=script&lang=js&"
export * from "./ConfirmedView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95da98a8",
  null
  
)

export default component.exports