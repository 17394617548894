import { render, staticRenderFns } from "./BookingDialogStepProduct2.vue?vue&type=template&id=df193330&scoped=true&"
import script from "./BookingDialogStepProduct2.vue?vue&type=script&lang=js&"
export * from "./BookingDialogStepProduct2.vue?vue&type=script&lang=js&"
import style0 from "./BookingDialogStepProduct2.vue?vue&type=style&index=0&id=df193330&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df193330",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ColorsProductV2: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/ColorsProductV2.vue').default})
