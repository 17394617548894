import { render, staticRenderFns } from "./StripeFormHeader.vue?vue&type=template&id=156a3d82&scoped=true&"
import script from "./StripeFormHeader.vue?vue&type=script&lang=js&"
export * from "./StripeFormHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "156a3d82",
  null
  
)

export default component.exports