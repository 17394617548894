import { render, staticRenderFns } from "./ProductNotAvailable.vue?vue&type=template&id=4cdef870&scoped=true&"
import script from "./ProductNotAvailable.vue?vue&type=script&lang=js&"
export * from "./ProductNotAvailable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdef870",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SmallCardProduct2: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/SmallCardProduct2.vue').default,CardProduct2: require('/home/jeanmarcphilippe/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/components/CardProduct2.vue').default})
