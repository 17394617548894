import { render, staticRenderFns } from "./GetCardCadeauShow.vue?vue&type=template&id=6c8376c1&"
import script from "./GetCardCadeauShow.vue?vue&type=script&lang=js&"
export * from "./GetCardCadeauShow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../nodevenv/public_html/dev.jeanmarcphilippe.com/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports